import React from "react"
import Typewriter from 'typewriter-effect'

interface Props {

}


const IndexTypeWritter: React.FC<Props> = (props) => (
    <Typewriter
        options={{
            strings: ['Hi People', 'Hola Gente', 'Hallo Leute'],
            autoStart: true,
            loop: true,
        }}
    />
)

export default IndexTypeWritter