import { projectInterface } from "../components/projectCard"

const openSourceContributions: Array<projectInterface> = [
    {
        title: "gspread",
        subtitle: "Python API for Google Sheets",
        link: "https://github.com/burnash/gspread"
    },
    {
        title: "Masonite orm",
        subtitle: "Drop in replacement of orator",
        link: "https://github.com/MasoniteFramework/orm"
    },
    {
        title: "bottle",
        subtitle: "Fast and simple micro-framework",
        link: "https://github.com/bottlepy/bottle"
    }
]

export {
    openSourceContributions
}