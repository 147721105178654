import { projectInterface } from "../components/projectCard"

const jobs: Array<projectInterface> = [
    {
        title: "Google TVC",
        subtitle: "Importance of understanding the problem",
        link: "/jobs/google"
    },
    {
        title: "Epikso",
        subtitle: "Consulting company, get solid on front-end development",
        link: "/jobs/epikso"
    },
    {
        title: "Bioteksa",
        subtitle: "ERP Microservice first Architecting steps",
        link: "/jobs/bioteksa"
    },
    {
        title: "Biobot.farm",
        subtitle: "Agritech. Managing teams. Backend Dev",
        link: "/jobs/biobot-farm"
    },
    {
        title: "Codepeat",
        subtitle: "Co-founder, Getting to now the buissness",
        link: "/jobs/codepeat"
    },
    {
        title: "Inmovending",
        subtitle: "First websites", 
        link: "/jobs/inmovending"
    },
    {
        title: "Before Engineering",
        subtitle: "What i did as a teen",
        link: "/jobs/before-engineering"
    }
]

export {
    jobs
}
