import { projectInterface } from "../components/projectCard"

const openSourceCreatedProjects: Array<projectInterface> = [
    {
        title: "mmp",
        subtitle: "Module Manager Python",
        link: "/summaries/mmp",
    },
    {
        title: "Orator Validator",
        subtitle: "Best way to validate orator models",
        link: "/summaries/orator-validator"
    },
    {
        title: "bottle-cors-plugin",
        subtitle: "Clean way to add the cors headers",
        link: "/summaries/bottle-cors-pluging"
    },
    {
        title: "Websockets-router",
        subtitle: "Multiple responses one ws server",
        link: "/summaries/websockets-router"
    },
    {
        title: "Matcha controls",
        subtitle: "Custom controls for hyper terminal",
        link: "/summaries/matcha-controls"
    }
]

export {
    openSourceCreatedProjects
}