import React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/layout"
import IndexTypeWritter from "../components/indexTypeWritter"
import { ProjectCardComponent } from "../components/projectCard"

import { jobs } from "../api/jobs"
import { openSourceCreatedProjects } from "../api/openSourceCreatedProjects"
import { openSourceContributions } from "../api/openSourceContributions"


export default function(props: PageProps) {
    return (
        <>
            <Layout title="Alfonsocvu">
                <h1><IndexTypeWritter/></h1>
                <p>
                    Welcome to my portfolio page, where you'll find an overview of my work experiences and projects.
                </p>
                <a href="mailto:alfonsocvu@gmail.com" className="button is-primary">
                    Email me
                </a>
                <h2 className="mt-6">Job Experience</h2>
                <p className="subtitle">Summary of my work</p>
                <div className="mx-auto flex flex-wrap px-auto">
                    {jobs.map((job) =>
                        < ProjectCardComponent
                            key={job.title}
                            title={job.title} subtitle={job.subtitle}
                            link={job.link}
                        />
                    )}
                </div>
                <h2 className="mt-6">Open Source</h2>
                <p className="subtitle">Created</p>
                <div className="mx-auto flex flex-wrap px-auto">
                    {openSourceCreatedProjects.map((project) =>
                        <ProjectCardComponent 
                            key={project.title}
                            title={project.title} subtitle={project.subtitle}
                            link={project.link}
                        />
                    )}
                </div>
                <p className="subtitle mt-6">Contributions</p>
                <div className="mx-auto flex flex-wrap px-auto">
                    {openSourceContributions.map((contribution) =>
                        <ProjectCardComponent
                            key={contribution.title}
                            title={contribution.title} subtitle={contribution.subtitle}
                            link={contribution.link}
                        />
                    )}
                </div>
            </Layout>
        </>
    )
}